import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useLocation } from "react-router-dom";
import Button from "./shared/components/FormElements/Button";
import dentons from "./media/dentons.png"

const Inicio = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <div className="text-center h2 my-2 pt-md-4 mt-md-5">CONSULTORÍA ESTRATÉGICA</div>
      <div className="d-flex flex-column">
        <div className="order-sm-2">
          <Carousel controls={false}>
            <Carousel.Item>
              <img
                className="w-100 height-special-img"
                src="./media/Digital.jpg"
                alt="Digital"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="w-100 height-special-img"
                src="./media/Meeting.jpg"
                alt="Presentation"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="w-100 height-special-img"
                src="./media/SmartObjectives.png"
                alt="Smart Objectives"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="mx-md-auto width-special height-special-p m-3 mt-md-1 order-sm-1 ">
          Los procesos de transformación requieren del uso de la tecnología,
          para que de forma predictiva, las herramientas digitales permitan
          implementar soluciones ágiles.
          <br />
          <br />
          La denominada Cuarta Evolución Industrial ha hecho que el proceso de
          digitalización crezca de forma exponencial, así es que la utilización
          de la Inteligencia Artificial, el Big Data, el Internet de las Cosas,
          en la actualidad son esenciales para los procesos de digitalización.
        </div>
      </div>
      <div className="tertiary-color">
        <div className="d-sm-flex align-items-center py-5 height-special-div">
          <div className="width-special">
            <div className="mx-3 py-4 w-75 text-md-end h1">
              Nuestros servicios
            </div>
          </div>
          <div className="mx-3 py-4 width-special">
            <div className="width-special-75">
              Buscamos apoyar la inteligencia digital e innovación colectivas.
              Considerando que las mejores prácticas internacionales permiten
              tener curvas de aprendizaje.
              <br />
              <br />
              Queremos ser un socio estratégico en los proyectos que asesoramos,
              brindando know-how y la experiencia de nuestro equipo en todas sus
              fases, asesoría, diseño e implementación.
            </div>
          </div>
        </div>
        <div id="servicios" className="height-special-div">
          <Carousel className="pb-6" controls={false}>
            <Carousel.Item>
              <div className="d-md-inline-flex flex-md-nowrap align-self-center">
                <div className="m-3 width-special">
                  <img
                    className="d-block w-75 mx-auto"
                    src="./media/DigitalTransformation.jpg"
                    alt="Transformación digital"
                  />
                </div>
                <div className="width-special">
                  <div className="h4 mx-3 py-4">Transformación digital</div>
                  <div className="mx-3 width-special-75">
                    El Internet de las Cosas y el Big Data comprenden las
                    tecnologías de punta de la digitalización, que nos permiten
                    adelantarnos a la toma de decisiones basados en el análisis
                    de la información. Esta tecnología es parte de la Industria
                    4.0 que promueve a distintos sectores productivos, a una
                    competitividad sistémica para beneficio de los individuos y
                    de la colectividad. Esto es la transformación digital.
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-md-inline-flex flex-md-nowrap align-self-center">
                <div className="m-3 width-special">
                  <img
                    className="d-block w-75 mx-auto"
                    src="./media/ChangeManagement.jpg"
                    alt="Change Management"
                  />
                </div>
                <div className="width-special">
                  <div className="h4 mx-3 py-4">
                    Asesoría en Change Management, Scenario Planning y Supply
                    Chain
                  </div>
                  <div className="mx-3">
                    El mundo actual cambia a pasos acelerados y hace falta
                    mantenerse al mismo nivel para crecer en conjunto. Por esto,
                    la adaptabilidad empresarial es una de las estrategias mas
                    importantes y debe manejarse bajo un esquema medible y de
                    mejora. Así, la Gestión del Cambio se vuelve imperativa
                    dentro de las organizaciones, consiguiendo que los equipos
                    internos caminen en la misma línea de la planificación
                    estratégica, tanto como la gestión de proveedores, obtención
                    de productos, materia prima, producción, medición de
                    resultados, operaciones y más.
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-md-inline-flex flex-md-nowrap align-self-center">
                <div className="m-3 width-special">
                  <img
                    className="d-block w-75 mx-auto"
                    src="./media/Proyectos.jpg"
                    alt="Proyectos"
                  />
                </div>
                <div className="width-special">
                  <div className="h4 mx-3 py-4">
                    Estructuración de proyectos
                  </div>
                  <div className="mx-3">
                    Cada nuevo proyecto requiere de un enfoque planificado, de
                    tal forma que, a través de un conjunto de pautas de alto
                    rendimiento para tomar decisiones y emprender acciones en
                    consonancia con las estrategias corporativas, comerciales,
                    de marketing y operativas, promuevan el éxito de cada nueva
                    idea.
                  </div>
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <Button type="button" href="/proyectos">
                      Conoce más
                    </Button>
                    <img
                      src={dentons}
                      alt="dentons shadow"
                      className="ms-3"
                      style={{ height: '45px', width: 'auto' }}
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div
        className="d-flex justify-content-evenly align-items-center py-5"
        id="acerca"
      >
        <div className="h2 px-md-5 text-center text-md-end w-50">Acerca de</div>
        <div className="h2 px-md-5 text-md-start w-50">
          <a href="/">
            <img
              style={{ width: "100px" }}
              src="./media/Imagotipo.jpg"
              alt="TERARI Imagotipo"
            />
          </a>
        </div>
      </div>
      <div className="mx-3 mx-md-auto pt-3 pb-5 width-special justify-content-center height-special-p2">
        En TERARI S.A.S. encontrará un socio estratégico que le ayudará a
        identificar oportunidades y abordar soluciones ágiles de alto valor
        agregado. Tenemos experiencia en varias industrias, lo que nos hace un
        interlocutor adecuado para trabajar conjuntamente en proyectos de alto
        impacto.
      </div>
    </>
  );
};

export default Inicio;
