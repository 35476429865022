import React from "react";
import { NavLink } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

const Headerh = () => {
  return (
    <>
      <div
        className="d-flex flex-md-column my-4"
        role="navigation"
      >
        <div className="mx-auto pt-md-3 w-50 text-center">
          <img
            style={{ width: "100px" }}
            src="./media/Logo.png"
            alt="TERARI Logo"
          />
        </div>
        <div className="align-self-center" style={{zIndex: "10"}}>
          <Navbar className="pb-md-4 bg-white menu-button" bg="light" expand="lg">
            <Container className="d-flex flex-wrap justify-content-evenly">
              <Navbar.Toggle aria-controls="basic-navbar-nav" className=""/>
              <Navbar.Collapse className="py-3 py-md-0" id="basic-navbar-nav">
                <Nav className="mx-auto">
                  <NavLink className="nav-link" to="/">
                    Inicio
                  </NavLink>
                  <NavLink className="nav-link" to="/proyectos">
                    Estructuración de proyectos
                  </NavLink>
                  <NavLink className="nav-link" to="/#servicios">
                    Servicios
                  </NavLink>
                  <NavLink className="nav-link" to="/#acerca">
                    Acerca de
                  </NavLink>
                  <Nav.Link className="nav-link">Contacto</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Headerh;
