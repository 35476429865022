import React from "react";
import TwitterFeed from "./shared/components/TwitterFeed";
import ContactForm from "./shared/components/ContactForm";
import XLogo from "./media/XLogo.webp"

const Footer = () => {
  return (
    <>
      <div className="tertiary-color">
        <div className="h1 mx-3 py-5">Contáctenos</div>
        <div className="d-sm-flex flex-wrap">
          <div className="width-special order-2">
            <div className="text-center py-md-5">
              Email:{" "}
              <a href="mailto:contacto@tearri.biz">contacto@terari.biz</a>
            </div>
            <div className="py-4" id="contacto">
              <div className="h4 text-center">Déjanos un mensaje</div>
              <div className="w-75 mx-auto">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="width-special order-1">
            <div className="py-5 text-center h4">Síguenos en redes</div>
            <div className="d-flex justify-content-evenly">
              <a
                href="https://twitter.com/TERARIconsulti2"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="d-block "
                  src={XLogo}
                  alt="Digital"
                  style={{ height: "50px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/terari-s-a-s/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="d-block w-100"
                  src="./media/LinkedInLogo.webp"
                  alt="Digital"
                />
              </a>
            </div>
          </div>
        </div>
        <p className="text-center p-5">
          Todos los derechos reservados por TERARI S.A.S. ©2022
        </p>
      </div>
    </>
  );
};

export default Footer;
