import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./Header";
import Headerh from "./Headerh";
import Footer from "./Footer";
import Inicio from "./Inicio";
import Proyectos from "./Proyectos";

const App = () => {
  return (
    <BrowserRouter>
      <main>
        <Headerh />
        <Routes>
          <Route index element={<Inicio />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="*" element={<div>PAGE NOT FOUND</div>} />
        </Routes>
        <Footer />
      </main>
    </BrowserRouter>
  );
};

export default App;
