import React from "react";
import Button from "./shared/components/FormElements/Button";
import imagedentons2 from "./media/dentons2.png"

const Proyectos = () => {
  const executeScroll = () => {
    let elem = document.getElementById("contacto");
    console.log(elem);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="text-center h2 py-5">Estructuración de proyectos</div>
      <div className="d-lg-inline-flex flex-lg-nowrap align-self-center">
        <div className="width-special">
          <img
            className="d-block w-100 mx-auto"
            src="./media/ProjectsMeeting.jpg"
            alt="Reunión de proyectos"
          />
        </div>
        <div className="width-special mx-md-5 bg-white">
          <div className="h3 py-5 mx-3">¿QUÉ HACEMOS?</div>
          <ul className="pb-4">
            <li>
              Generar y potenciar iniciativas de inversión privada en Ecuador.
            </li>
            <li>Levantamiento de portafolios de inversión por sectores</li>
            <li>
              Apoyar a las empresas a establecerse en Ecuador y hacer un due
              dilligence de posibilidades de proyectos, análisis de mercado,
              valoración de sectores, definición de actores, tiempos y demás
              temas relevantes a la inversión.
            </li>
            <li>Soft Landing empresarial</li>
            <li>
              Representar a los clientes frente a los distintos actores y
              asesorar en relaciones y estrategias con stakeholders.
            </li>
            <li>Levantar financiamiento para proyectos (Project Finance)</li>
          </ul>
        </div>
      </div>
      <div className="d-lg-inline-flex flex-lg-nowrap align-self-center p-md-5">
        <div className="mx-3 order-2">
          <img
            className="d-block w-100 width-image"
            src="./media/ChangeManagement.jpg"
            alt="Reunión de proyectos"
          />
        </div>
        <div className="align-self-center py-4 order-1">
          <div className="tertiary-color width-special-rel-75">
            <div className="h3 my-0 mx-3 py-5 px-2">¿QUÉ NOS DIFERENCIA?</div>
            <div className="mx-3 p-3">
              <div>Transparencia</div>
              <ul>
                <li>
                  Tenemos las más rigurosas políticas de transparencia interna.
                </li>
                <li>
                  Aseguramos la credibilidad de los procesos en los que
                  participen nuestros clientes e inversores.
                </li>
              </ul>
              <div>Conocimiento de mercado</div>
              <ul>
                <li>
                  Entendimiento y contactos con los tomadores de decisiones.
                </li>
                <li>
                  Búsqueda de oportunidades con una visión empresarial
                  holística.
                </li>
                <li>
                  Relacionamiento con entidades financieras internacionales
                  (IFIs)
                </li>
              </ul>
              <div>Servicio personalizado</div>
              <ul>
                <li>Asesoría directa a los socios.</li>
                <li>
                  Acompañamiento personalizado en todas las fases de la
                  inversión.
                </li>
              </ul>
              <div className="p-3">
                <Button type="button" onClick={executeScroll}>
                  Contáctanos
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-lg-inline-flex flex-lg-nowrap w-150  bg-light">
        <div className="px-3 width-special align-self-center">
          <h3 className="p-3 h3 text-black">DENTONS</h3>
          <p className="px-3">
            TERARI SAS y DENTONS PAZ HOROWITZ han suscrito un acuerdo comercial que permitirá a los clientes de TERARI  recibir   soluciones de inversión, legales  y acompañamiento en el desarrollo de nuevas oportunidades de negocios, dentro y fuera del Ecuador.
          </p>
          <p className="px-3">
            DENTONS es la firma de abogados más grande del mundo.          </p>
        </div>
        <div className="width-special align-self-center">
          <img
            className="d-block w-50 mx-auto "
            src={imagedentons2}
            alt="DENTONS"
          />
        </div>
      </div>
      <div>
        <div className="h3 py-5 text-center">NUESTRAS HABILIDADES</div>
        <div className="row row-cols-lg-3 bg-dark">
          <div className="containerCSS text-white h4">
            <img
              className="d-block w-100 filter-image"
              src="./media/building.jpeg"
              alt="Reunión de proyectos"
            />
            <div className="centered">
              ENTENDIMIENTO DEL IMPACTO AMBIENTAL Y SOCIAL DE LOS PROYECTOS DE
              INVERSIÓN
            </div>
          </div>
          <div className="containerCSS text-white h4">
            <img
              className="d-block w-100 filter-image"
              src="./media/stocks.jpeg"
              alt="Reunión de proyectos"
            />
            <div className="centered">
              ENTENDIMIENTO DE LOS ESTÁNDARES INTERNACIONALES DE BANCABILIDAD DE
              PROYECTOS
            </div>
          </div>
          <div className="containerCSS text-white h4">
            <img
              className="d-block w-100 filter-image"
              src="./media/meet2.jpeg"
              alt="Reunión de proyectos"
            />
            <div className="centered">
              ENTENDIMIENTO DE LOS CRITERIOS, PROBLEMAS Y VISIÓN DE LOS
              INVERSIONISTAS
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-3 p-md-3">
        <div className="h2 py-5">ESPECIALIZACIÓN</div>
        <div className="row row-cols-md-3">
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Hidrocarburos.jpg"
              alt="Hidrocarburos"
            />
            <div className="py-2 h4">HIDROCARBUROS</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Telecomunicaciones.jpg"
              alt="Telecomunicaciones"
            />
            <div className="py-2 h4">TELECOMUNICACIONES</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Salud.png"
              alt="Salud"
            />
            <div className="py-2 h4">SALUD</div>
          </div>
        </div>
        <div className="row row-cols-md-3">
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Vialidad.jpg"
              alt="Vialidad"
            />
            <div className="py-2 h4">VIALIDAD</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Logistica.jpg"
              alt="Logistica"
            />
            <div className="py-2 h4">LOGISTICA</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Electricidad.jpg"
              alt="Electricidad"
            />
            <div className="py-2 h4">ELECTRICIDAD</div>
          </div>
        </div>
        <div className="row row-cols-md-3">
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Aeropuertos.png"
              alt="Aeropuertos"
            />
            <div className="py-2 h4">AEROPUERTOS</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Puertos.png"
              alt="Puertos"
            />
            <div className="py-2 h4">PUERTOS</div>
          </div>
          <div className="py-4">
            <img
              className="d-block mx-auto w-25 image-height"
              src="./media/Mineria.jpg"
              alt="Minería"
            />
            <div className="py-2 h4">MINERÍA</div>
          </div>
        </div>
      </div>

      <div className="d-lg-inline-flex flex-lg-nowrap w-100 tertiary-color">
        <div className="px-3 width-special align-self-center">
          <div className="py-5 h1 text-black">DOSSIER</div>
          <div className="p-3">Producto base para inversionistas</div>
          <ul className="py-3">
            <li>
              ELABORACIÓN EN CONJUNTO CON ACTORES RELEVANTES NACIONALES E
              INTERNACIONALES
            </li>
            <li>ANÁLISIS SITUACIONAL DE LA INDUSTRIA ESPECÍFICA</li>
            <li>ANÁLISIS DE VALORACIÓN DE RIESGOS</li>
            <li>ANÁLISIS ESTRATÉGICO, TÁCTICO Y OPERACIONAL</li>
            <li>ANÁLISIS DE HOJA DE RUTA</li>
          </ul>
        </div>
        <div className="width-special">
          <img
            className="d-block w-100 mx-auto"
            src="./media/Reviewing.jpg"
            alt="Revisando"
          />
        </div>
      </div>
    </>
  );
};

export default Proyectos;
