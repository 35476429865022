import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const TwitterFeed = () => {
  return (
    <div className="mx-auto w-75 mh-100">
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="TERARIconsulti2"
        options={{ height: "800px", width: "100%"}}
      />
    </div>
  );
};

export default TwitterFeed;